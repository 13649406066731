<template>
  <div class="right">
    <div class="tab">
      <div class="tab-cut">
        <div @click="handleCutTab(true)" :class="{'tab-cut-item-active':tabActive}">{{$t('member_right.s_vip_member')}}</div>
        <div @click="handleCutTab(false)" :class="{'tab-cut-item-active':!tabActive}">{{$t('member_right.vip_member')}}</div>
      </div>
    </div>
    <div class="tab-sm">
      <div @click="handleCutTab(true)" :class="{'tab-sm-item-active':tabActive}">{{$t('member_right.s_vip_member')}}</div>
      <div @click="handleCutTab(false)" :class="{'tab-sm-item-active':!tabActive}">{{$t('member_right.vip_member')}}</div>
    </div>
    <div class="container">
      <div class="content">
        <div class="title"><span v-show="tabActive">S</span>{{$t('member_right.vip_enjoy')}} <span v-show="tabActive">9</span> <span v-show="!tabActive">6</span> {{$t('member_right.big_rights')}}</div>
        <div class="title-sm">
          <div style="font-size: 16px;margin-top: 37px;color: #947348;text-align: center">Felton reinvents the Trading</div>
          <div>{{$t('member_right.member_title')}}</div>
          <div>{{$t('member_right.member_title_sub1')}}<span v-show="tabActive">S</span>VIP{{$t('member_right.enjoy')}}<span v-show="tabActive">9</span><span v-show="!tabActive">6</span>{{$t('member_right.big_rights')}}</div>
        </div>

        <div class="icons">

          <div class="icon" v-if="tabActive">
            <img src="../../../assets/img/member/rights/right_seven.svg" alt="">
            <div>
              <div>{{ $t('member_right.content_title7_svip') }}</div>
              <div>{{ $t('member_right.content_info7_s_svip') }}</div>
            </div>
          </div>

          <div class="icon" v-if="tabActive">
            <img src="../../../assets/img/member/rights/right_one.svg" alt="">
            <div>
              <div>{{ $t('member_right.content_title') }}</div>
              <div>{{ $t('member_right.content_info') }}</div>
            </div>
          </div>

          <div class="icon">
            <img src="../../../assets/img/member/rights/right_two.svg" alt="">
            <div>
              <div>{{ $t('member_right.high_reach') }}<span v-show="tabActive">8+12</span> <span
                  v-show="!tabActive">3+8</span>{{ $t('member_right.content_title2') }}
              </div>
              <div>
                <span v-show="tabActive">SVIP{{ $t('member_right.content_info2_s') }}</span>
                <span v-show="!tabActive">VIP{{ $t('member_right.content_info2_v') }}</span>
              </div>
            </div>
          </div>

          <div class="icon" v-show="tabActive">
            <img src="../../../assets/img/member/rights/right_three.svg" alt="">
            <div>
              <div>{{ $t('member_right.content_title3') }}</div>
              <div>{{ $t('member_right.content_info3_s') }}</div>
            </div>
          </div>

          <div class="icon" v-show="tabActive">
            <img src="../../../assets/img/member/rights/right_four.svg" alt="">
            <div>
              <div>{{ $t('member_right.content_title4') }}</div>
              <div>{{ $t('member_right.content_info4_s') }}</div>
            </div>
          </div>

          <div class="icon">
            <img src="../../../assets/img/member/rights/right_five.svg" alt="">
            <div>
              <div>{{ $t('member_right.content_title5') }}</div>
              <div>{{ $t('member_right.content_info5_s_min') }}<span
                  v-show="tabActive">S</span>{{ $t('member_right.content_info5_s') }}
              </div>
            </div>
          </div>

          <div class="icon">
            <img src="../../../assets/img/member/rights/right_six.svg" alt="">
            <div>
              <div>{{ $t('member_right.content_title6') }}</div>
              <div>{{ $t('member_right.content_info6_s') }}</div>
            </div>
          </div>

          <div class="icon" v-if="!tabActive">
            <img src="../../../assets/img/member/rights/right_seven.svg" alt="">
            <div>
              <div>{{$t('member_right.content_title7')}}</div>
              <div>{{$t('member_right.content_info7_s')}}</div>
            </div>
          </div>

          <div class="icon">
            <img src="../../../assets/img/member/rights/right_eight.svg" alt="">
            <div>
              <div>{{ $t('member_right.content_title8') }}</div>
              <div>{{ $t('member_right.content_info8_s') }}</div>
            </div>
          </div>

          <div class="icon">
            <img src="../../../assets/img/member/rights/right_nine.svg" alt="">
            <div>
              <div>{{ $t('member_right.content_title9') }}</div>
              <div>{{ $t('member_right.content_info9_s') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="member-rush">
      <transition name="el-fade-in">
      <div @click="$router.push('/member_service_agreement')" class="agreement" style="cursor: pointer" v-show="scrollIsBottom">{{$t('member_right.confirm_agreement')}}</div>
      </transition>
      <div class="member-rush-content" :class="{'rush-active':!tabActive}">
        <div style="position: relative" >
          <span>{{memberPrice}}</span>
          <span>USDT/{{$t('member_right.year')}}</span>
<!--          <span v-if="memberRemainInfo.duration_type == 0 && memberRemainInfo.vip_type == 2 && tabActive==true" style="text-decoration: unset">{{$t('member_right.After_trial_expires_tip')}}</span>-->
<!--          <span v-else>{{$t('member_right.prime_price')}}：{{originalPrice}}USDT/{{$t('member_right.year')}}</span>-->
          <span>{{$t('member_right.prime_price')}}：{{originalPrice}}USDT/{{$t('member_right.year')}}</span>
<!--          <div @click="$emit('handleRushOrder')">-->
<!--            <span v-if="userInfo.charge_style2==1&&tabActive==false">{{$t('member_right.eight_renew')}}</span>-->
<!--            <span v-else-if="userInfo.charge_style2==2&&tabActive==true">{{$t('member_right.eight_renew')}}</span>-->
<!--            <span v-else>{{$t('member_right.buy_it_now')}}</span>-->
<!--          </div>-->
          <div @click="$emit('handleRushOrder')">
            <span v-if="userInfo.charge_style2==1&&tabActive==false">{{$t('member_right.eight_renew')}}</span>
            <span v-else-if="(userInfo.charge_style2==2 && memberRemainInfo.duration_type != 0)&&tabActive==true">{{$t('member_right.eight_renew')}}</span>
            <span v-else-if="memberRemainInfo.duration_type == 0 && memberRemainInfo.vip_type == 2 && tabActive==true">{{$t('member_right.buy_it_now')}}</span>
            <span v-else>{{$t('member_right.buy_it_now')}}</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Member_right",
  data(){
    return{
      tabActive:true,
      scrollIsBottom:false
    }
  },
  props:['memberPrice','originalPrice','tabActiveProp','userInfo','memberRemainInfo'],
  created() {
    window.addEventListener('scroll',this.handleScrollChange)
  },
  watch:{
    tabActiveProp:{
      handler: function (newValue){
        this.tabActive=newValue
      },
      immediate:true
    }
  },
  methods: {
    handleCutTab(e) {
      this.tabActive=e
      this.$emit('handleCutState',this.tabActive)
    },
    handleScrollChange() {
      var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
      //滚动条到底部的条件
      if(scrollTop+windowHeight+50>=scrollHeight) this.scrollIsBottom=true
      else this.scrollIsBottom=false

    },
  },
  destroyed() {
    window.removeEventListener('scroll',this.handleScrollChange)
  }
}
</script>

<style scoped>
  .tab{
    background: #F4F1EC;
    padding: 0 30px;
  }
  .container{
    padding: 0 30px 152px 30px;
  }
  .tab-cut{
    max-width: 1200px;
    margin: 0 auto;
  }
  .tab-cut>div{
    display: inline-block;
    width: 160px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    color: #1E2329;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    opacity: 0.5;
  }
  .tab-cut-item-active{
    background: #FFFFFF;
    color: #947348 !important;
    border-top: 2px solid #C6AB7E;
    opacity: 1 !important;
  }
  /*content*/
  .content{
    max-width: 1200px;
    margin: 0 auto;
  }
  .title{
    margin: 48px 0 32px 0;
    font-size: 24px;
    font-weight: 500;
    color: #947348;
    text-align: center;
  }
  .icons>div{
    display: inline-block;
    width: 30%;
    margin-bottom: 48px;
  }
  .icon>img{
    width: 18.7%;
    vertical-align: middle;
  }
  .icon>div{
    margin-left: 4.3%;
    display: inline-block;
    width: 77%;
    vertical-align: middle;
  }
  .icon>div>div:first-child{
    font-size: 16px;
    font-weight: 500;
    color: #1E2329;
    margin-bottom: 6px;
  }
  .icon>div>div:nth-child(2){
    font-size: 14px;
    font-weight: 400;
    color: #72777E;
  }
  .icon:nth-child(3n-1){
    margin: 0 5%;
  }

  /*sm-title*/
  .title-sm{
    display: none;
  }
  .title-sm>div:nth-child(2){
    font-size: 20px;
    font-weight: 500;
    color: #947348;
    text-align: center;
    /*margin-top: 47px;*/
  }
  .title-sm>div:nth-child(3){
    font-size: 16px;
    font-weight: 400;
    color: #C5AB7E;
    margin: 6px 0 40px 0;
    text-align: center;
    margin: 6px 0 40px 0;
  }
  .tab-sm{display: none}
  .tab-sm>div{
    width: 50%;
    height: 42px;
    display: inline-block;
    text-align: center;
    line-height: 42px;
    background: #F4F1EC;
    font-size: 16px;
    font-weight: 500;
    color: #C6BFB8;
  }
  .tab-sm-item-active{
    background: linear-gradient(180deg, #947348 0%, #C6AB7E 100%) !important;
    color: #F7EFE0 !important;
  }
  @media screen and (max-width: 900px){
    .title-sm{
      display: block;
    }
    .title{
      display: none;
    }
    .tab{
      background: #F4F1EC;
      padding: 0 16px;
      display: none;
    }
    .tab-sm{
      display: block;
    }
    .container{
      padding: 0 16px 91px 16px;
    }
    .icon>img{
      width: 68px;
      vertical-align: middle;
    }
    .icons>div{
      display: flex ;
      flex-wrap: nowrap;
      width: 100%;
      margin-bottom: 40px;
    }
    .icon>div{
      margin-left: 16px;
      display: inline-block;
      min-width: 259px;
      vertical-align: middle;
    }
    .icon:nth-child(3n-1){
      margin: 0 0 40px 0;
    }
  }

/*  底部抢购功能*/
  .member-rush{
    width: 100%;
    position: fixed;
    bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 100;
  }
  .member-rush>div:first-child{
    font-size: 12px;
    font-weight: 400;
    color: #BFAB91;
    margin-bottom: 16px;
    width: 100%;
    text-align: center;
  }
  .member-rush>div:nth-child(2){
    width: 474px;
    height: 48px;
    background: linear-gradient(180deg, #151842 0%, #252966 100%);
    border-radius: 2px;
  }
  .member-rush>div:nth-child(2)>div>span:first-child{
    background: linear-gradient(125deg, #EBD9C1 0%, #C6AB7E 100%);
    /*background-clip: text;*/
    -webkit-background-clip:text;
    color: transparent;
    font-size: 26px;
    font-weight: bold;
    /*line-height: 48px;*/
    margin: 0 7px 0 24px;
  }
  .member-rush>div:nth-child(2)>div>span:nth-child(2){
    font-size: 14px;
    font-weight: 500;
    color: #F7EFE0;
  }
  .member-rush>div:nth-child(2)>div>span:nth-child(3){
    font-size: 14px;
    font-weight: 400;
    color: #F7EFE0;
    margin-left: 16px;
    text-decoration: line-through;
  }
  .member-rush>div:nth-child(2)>div:first-child{
    display: flex;
    align-items: center;
    height: 100%;
  }
  .member-rush>div:nth-child(2)>div>div{
    width: 156px;
    height: 52px;
    bottom: 0;
    right: 0;
    z-index: 101;
    position: absolute;
    background: url('../../../assets/img/member/rush.svg');
    font-size: 18px;
    font-weight: 500;
    color: #F7EFE0;
    line-height: 52px;
    text-align: center;
    cursor: pointer;
  }
@media screen and (max-width: 900px){
  .member-rush{
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 100;
    padding-top: 0;
    padding-bottom: 0;
  }

  .member-rush>div:first-child{
    font-size: 12px;
    font-weight: 400;
    color: #BFAB91;
    margin-bottom: 16px;
    width: 100%;
    text-align: center;
  }
  .member-rush>div:nth-child(2)>div>span:first-child{
    background: linear-gradient(125deg, #EBD9C1 0%, #C6AB7E 100%);
    /*background-clip: text;*/
    -webkit-background-clip:text;
    color: transparent;
    font-size: 26px;
    font-weight: bold;
    /*line-height: 48px;*/
    margin: 0 7px 0 20px;
  }
  .member-rush>div:nth-child(2)>div>span:nth-child(2){
    font-size: 14px;
    font-weight: 500;
    color: #F7EFE0;
  }
  .member-rush>div:nth-child(2)>div>span:nth-child(3){
    font-size: 12px;
    font-weight: 400;
    color: #F7EFE0;
    margin-left: 11px;
  }
  .member-rush>div:nth-child(2)>div>div{
    width: 111px;
    height: 52px;
  }
}

  /**
    vip的不同样式
   */
  .rush-active{
    width: 474px;
    height: 48px;
    background: linear-gradient(180deg, #292A2C 0%, #09090A 100%) !important;
    border-radius: 2px;
  }


</style>
