<template>
  <div class="member">
    <div class="nav nav-lg">
      <div @click="$router.go(-1)"><img src="../../assets/img/member/phone.svg" alt=""><span>{{$t('member.go_back')}}</span></div>
      <div><img src="../../assets/img/member/member.svg" alt=""><span>Felton{{$t('member.member_center')}}</span></div>
      <div @click="activeCodeDialogVisible=true" style="width: 88px;height: 26px;border-radius: 4px;line-height: 26px;text-align: center;border: 1px solid #F7EFE0;"><span>{{$t('member.user_activation_code')}}</span></div>
    </div>
    <div class="black" :class="{'black-active':!tabActive}" style="position: relative">
      <div class="container" style="position: relative">
        <div class="nav nav-sm">
          <div @click="$router.go(-1)"><img src="../../assets/img/member/phone.svg" alt=""><span>{{$t('member.member_right')}}</span></div>
          <div @click="activeCodeDialogVisible=true" style="border-radius: 4px;border: 1px solid #C6AB7E;width: 88px;height: 26px;font-size: 14px;color: #C6AB7E;text-align: center;line-height: 26px;">{{$t('member.user_activation_code')}}</div>
        </div>
        <div class="content">
          <div class="title">
            <div style="font-size: 30px;color: #F4E0BE;margin-bottom: 10px">Felton reinvents the Trading</div>
            <div>{{$t('member.member_title')}}</div>
            <div>{{$t('member.member_title_sub')}}<span v-show="tabActive">S</span>VIP{{$t('member.member_title_sub2')}}</div>
          </div>
          <div class="info">
            <img v-if="userInfo.charge_style2==2||userInfo.charge_style2==3" src="../../assets/img/member/svip_big.svg" alt="">
            <img v-if="userInfo.charge_style2==1" src="../../assets/img/member/vip_big.svg" alt="">
            <div>
              <div><span>{{username}}</span>
                <img style="vertical-align: middle" v-if="userInfo.charge_style2==3" src="../../assets/img/ssvip.svg" alt="">
                <img style="vertical-align: middle" v-else-if="userInfo.charge_style2==2" src="../../assets/img/member/svip.svg" alt="">
                <img style="vertical-align: middle" v-else-if="userInfo.charge_style2==1" src="../../assets/img/member/vip.svg" alt="">
                <img style="vertical-align: middle;margin-left: 5px" v-if="userInfo.charge_style2 == 2 || userInfo.charge_style2 == 3" src="../../assets/img/user/discount.svg">

              </div>
              <div><span style="opacity: 0.65">{{memberRemainNum}}</span>
<!--                <span @click="confirmOrderDialogVisible=true;tabActive=true;handleCutMember(true)" v-if="(userInfo.charge_style2==2|| userInfo.charge_style2==3) &&memberRemainInfo.duration_type!=2"  style="border: none">{{$t('member.renew_eight_discount')}}</span>-->
                <span @click="tabActive=true;confirmOrderDialogVisible=true;handleCutMember(true)" v-if="userInfo.charge_style2==1&&memberRemainInfo.duration_type!=2"  style="border: none;">{{$t('member.upgrade')}}SVIP</span>
<!--                <span @click="activeCodeDialogVisible=true">使用激活码</span> <span>我的訂單</span> -->
              </div>
            </div>
          </div>
<!--          <div class="balance">-->
<!--            <span>我的資產餘額:<span>189.546</span>USDT</span>-->
<!--            <span>-->
<!--              <span>充值</span>-->
<!--              <span>提現</span>-->
<!--            </span>-->
<!--          </div>-->
        </div>
        <img style="position: absolute;right: 0;top: 128px" src="../../assets/img/member/pic.svg" alt="">
      </div>
      <img style="position: absolute;right: 0;top:0;" src="../../assets/img/member/circle.svg" alt="">
    </div>
    <Member_right :tabActiveProp="tabActive" :originalPrice="originalPrice" :memberPrice="memberPrice" :userInfo="userInfo" :memberRemainInfo="memberRemainInfo" @handleCutState="handleCutMember" @handleRushOrder="confirmOrderDialogVisible=true"></Member_right>


<!--    激活码的对话框-->
    <el-dialog
        :title="$t('member.user_activation_code')"
        :visible.sync="activeCodeDialogVisible"
        class="member-dialog"
        width="510px">
      <el-form ref="activeCodeFormRef" :model="activeCodeForm" :rules="activeCodeFormRules" style="margin: -5px 0 0px 0;" @submit.native.prevent>
        <el-form-item prop="code">
          <el-input v-model="activeCodeForm.code" :placeholder="$t('member.please_input_member_activation_code')"></el-input>
        </el-form-item>
      </el-form>
      <el-button @click="handleActiveMember" style="margin-top: 10px;width: 100%;color: #F7EFE0;background: linear-gradient(180deg, #C6AB7E 0%, #947348 100%);">{{$t('member.conversion')}}</el-button>
    </el-dialog>

<!--        確認訂單-->
    <el-dialog
        :title="$t('member.confirm_order_form')"
        :visible.sync="confirmOrderDialogVisible"
        width="510px">
      <div style="display: flex;justify-content: center;flex-wrap: wrap">
        <div style="width: 100%;text-align:center;font-size: 18px;color: #1E2329;"><span v-show="tabActive">S</span>VIP{{$t('member.member_privilege')}}</div>
        <div style="text-align:center;width: 240px;border-bottom: 1px solid rgba(19, 20, 21, 0.1);padding: 14px 0 18px 0;font-size: 16px;color: #1E2329;">
<!--          {{$t('member.order_money')}} : <span style="color: #96754A;font-size: 26px;">{{ memberPrice }}</span> USDT <span v-if="(userInfo.charge_style2==2&&tabActive==true)||(tabActive==false&&userInfo.charge_style2==1)"><img src="../../assets/img/member/eight_dis.svg" alt=""></span></div>-->
          {{$t('member.order_money')}} : <span style="color: #96754A;font-size: 26px;">{{ memberPrice }}</span> USDT <span v-if="(userInfo.charge_style2==2&&tabActive==true)||(tabActive==false&&userInfo.charge_style2==1)"></span></div>
<!--        <div style="width: 100%;text-align:center;margin: 15px 0 4px 0;font-size: 16px;color: #1E2329;">{{$t('member.property_money')}} : <span style="color: #96754A;">{{userInfo.deposit}}</span> USDT</div>-->
        <div style="width: 100%;text-align:center;margin: 15px 0 4px 0;font-size: 16px;color: #1E2329;">{{$t('member.property_money_new')}} : <span style="color: #96754A;">{{userInfo.asset_balance}}</span> USDT</div>
        <div v-if="userInfo.asset_balance<memberPrice" style="font-size: 14px;color: #FF0000;">{{$t('member.balance_lack_new')}} <span @click="getTopUpInfo" style="color: #96754A;cursor: pointer;text-decoration: underline">{{$t('member.top_up')}}</span></div>
        <div v-else style="font-size: 14px;color: #80848A;">{{$t('member.support_type_new')}}</div>
      </div>
      <el-form ref="confirmOrderFormRef" :model="confirmOrderForm" :rules="confirmOrderFormRules" style="margin: 20px 0 24px 0;" @submit.native.prevent>
        <el-form-item v-if="userInfo.username!==''&&userInfo.username!==null" prop="auth_code">
          <el-input v-model="confirmOrderForm.auth_code" :placeholder="$t('member.please_input_email_auth_code')"></el-input>
          <div class="auth-code">
            <div v-if="isEmailSent" @click="sendAuthCode('email')" style="font-size: 14px;font-weight: 400;color: #96754A;">{{$t('member.send_auth_code')}}</div>
            <div v-else><span>{{emailSeconds}}</span><span>{{$t('member.regain')}}</span></div>
          </div>
        </el-form-item>
        <el-form-item v-else prop="auth_code">
          <el-input v-model="confirmOrderForm.auth_code" :placeholder="$t('member.please_input_phone_auth_code')"></el-input>
          <div class="auth-code">
            <div v-if="isEmailSent" @click="sendAuthCode('phone')" style="font-size: 14px;font-weight: 400;color: #96754A;">{{$t('member.send_auth_code')}}</div>
            <div v-else><span>{{emailSeconds}}</span><span>{{$t('member.regain')}}</span></div>
          </div>
        </el-form-item>
      </el-form>
      <el-button @click="handleBuyMember" v-if="userInfo.asset_balance>=memberPrice" style="margin-bottom: 24px;width: 100%;color: #F7EFE0;background: linear-gradient(180deg, #C6AB7E 0%, #947348 100%);">{{$t('member.confirm_open')}}</el-button>
      <el-button @click="$message.warning($t('member.balance_lack_tip'))" v-else style="opacity: 0.5;margin-bottom: 24px;width: 100%;color: #F7EFE0;background: linear-gradient(180deg, #C6AB7E 0%, #947348 100%) rgba(255, 255, 255, 0.5);">{{$t('member.confirm_open')}}</el-button>
    </el-dialog>

    <!--    充值对话框-->
    <el-dialog
        :visible.sync="topUpDialogVisible"
        width="510px"
        :title="$t('member.property_top_up')"
        class="topUpDialog"
        :close-on-click-modal="false"
        height="304px">
      <div class="topUp">
        <div class="topUp_top">
          <span style="display: block;">充值账户<tooltip content="一旦我们收到您充值的资产，会自动上账到您所选择的账户。"></tooltip></span>
          <el-select disabled style="display:block;position: relative;margin-top: 5px" v-model="topUpInfo.rechargeTarget" name="">
            <el-option value="1" label="资金账户"></el-option>
            <el-option value="2" label="交易账户（燃料费）"></el-option>
          </el-select>
        </div>
        <div style="margin: 15px 0 5px">{{$t('member.main_net')}}</div>
        <div style="margin: 8px 0 24px"><el-input disabled placeholder="TRC20"></el-input></div>
        <div>{{$t('member.wallet_address')}}</div>
        <div style="border-top: unset;width: fit-content;background: white;padding: 8px;margin: 8px 0 16px"><img :src="topUpInfo.qrCode" alt="" style="max-width: 120px;"></div>
        <div style="padding: 11px 0 24px;border-top: 1px solid rgba(19,20,21,.1);display: flex;justify-content: space-between;align-items: center;">
          <span>{{topUpInfo.base58Address}}</span><img @click="handleCopyAddress" style="cursor: pointer" src="../../assets/img/rebate/copy.svg" alt="">
        </div>
        <div>{{$t('member.important_tip')}}：</div>
        <div>
          <ul>
            <li>{{$t('member.least_top_up_money')}}50USDT</li>
            <li>{{$t('member.top_up_tip1')}}</li>
            <li>{{$t('member.top_up_tip2')}}</li>
          </ul>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="topUpDialogVisible=false" style="margin-bottom: 24px;width: 100%;color: #F7EFE0;;background: linear-gradient(180deg, #C6AB7E 0%, #947348 100%);">{{$t('member.user_already_top_up')}}</el-button>
      </span>
    </el-dialog>
<!--    兑换永久会员-->
    <el-dialog
        :visible.sync="buyPermanentSuccessDialog"
        width="510px"
        height="304px">
      <div style="text-align: center;">
        <img  v-if="tabActive" style="width: 104px;height: 112px" src="../../assets/img/member/svip_big.svg" alt="">
        <img v-else style="width: 104px;height: 112px" src="../../assets/img/member/vip_big.svg" alt="">
        <div style="margin: 33px 0 24px 0 ;color: #1E2329;font-size: 18px">{{$t('member.conversion_success')}}<span v-if="activeCodeInfo.duration_type==2">{{$t('member.perpetual')}}</span><span v-if="activeCodeInfo.vip_type==2||activeCodeInfo.vip_type==3">S</span>VIP!</div>
      </div>
      <el-button @click="buyPermanentSuccessDialog=false;" style="margin-bottom: 24px;width: 100%;color: #F7EFE0;;background: linear-gradient(180deg, #C6AB7E 0%, #947348 100%);">{{$t('member.i_see')}}</el-button>
    </el-dialog>

    <buy_success :tabActive="tabActive" @handleBuySuccess="buySuccess=false;" :buySuccess="buySuccess"></buy_success>
<!--    <Remind_pay></Remind_pay>-->
  </div>
</template>

<script>
import Member_right from "./member_content/Member_right";
import buy_success from "./member_content/buy_success";
import {checkEmail, checkEmailGeneral, checkMobileGeneral, checkTwoPlace} from "../../utils/validate";
import {getAuthCode} from "../../server/auth";
import {getUserInfo} from "../../server/rebate";
import axios from "axios";
import Remind_pay from "./member_content/Remind_pay";
import {activeCodeMember, addMemberOrder, buyMember, getMemberRemainNum} from "../../server/member";
import {getUserAddress, getUserAddressQrCode, setRechargeAccount} from "../../server/property";
import Tooltip from "../common/tooltip";
export default {
  name: "Member",
  data(){
    return{
      loading:false,
      username:'',
      tabActive:true,
      //購買成功對話框
      buySuccess:false,
      //兑换永久会员对话框
      buyPermanentSuccessDialog:false,
      //會員價格
      memberPrice:1980,
      originalPrice:6980,
      //会员剩余信息
      memberRemainInfo:{
        duration_type:1
      },
      //会员剩余天数
      memberRemainNumInfo:'',
      //激活碼的對話框
      activeCodeDialogVisible:false,
      activeCodeForm:{
        code:''
      },
      activeCodeFormRules:{
        code: [
          { required: true, message: this.$t('member.please_input_activation_auth_code'), trigger: 'blur' }],
      },
      // 確認開通的對話框
      confirmOrderDialogVisible:false,
      confirmOrderForm:{
        auth_code:''
      },
      confirmOrderFormRules:{
        auth_code: [
          { required: true, message: this.$t('member.please_input_auth_code'), trigger: 'blur' }],
      },
      //充值对话框
      topUpDialogVisible:false,
      topUpInfo:{
        base58Address:'',
        qrCode:'',
        rechargeTarget: '1'
      },

    //  驗證碼
      isEmailSent:true,
      emailSeconds:60,
      userInfo:{
        deposit:''
      },
    //  激活码兑换会员的返回信息
      activeCodeInfo:{}
    }
  },
  components:{
    Tooltip,
    Member_right,
    buy_success,
    // Remind_pay
  },
  created() {
    this.getUserInfo()
    this.getMemberRemainNum()
  },
  mounted() {
    if (this.$route.query.vipType){
      if (this.$route.query.vipType==1) {
        this.tabActive=false
        this.handleCutMember(this.tabActive)
        this.confirmOrderDialogVisible=true
      }else if (this.$route.query.vipType==2) {
        this.tabActive=true
        this.handleCutMember(this.tabActive)
        this.confirmOrderDialogVisible=true
      }
    }
  },
  computed:{
    memberRemainNum(){
      const memberInfo=this.memberRemainNumInfo
      if (memberInfo==null) return '2022.12.31 '+this.$t('member.expire')
      if (this.userInfo.charge_style2==3&&memberInfo.duration_type==2) return this.$t('member.perpetual_valid')
      if (this.userInfo.charge_style2==1&&memberInfo.duration_type==2) return this.$t('member.perpetual_valid')

      if (memberInfo.vip_type==0)   return  this.$t('member.not_yet_open')
      if (memberInfo.vip_type==2 || memberInfo.vip_type==3) {
        // const times=((new Date(memberInfo.svip_end_time).valueOf()-new Date().valueOf())/(1000*3600*24)).toFixed(0)
        const times=memberInfo.svip_end_time
        return `${(times.replace(/-/g,'.')).slice(0,times.indexOf(' '))}  `+this.$t('member.expire')
      }
      if (memberInfo.vip_type==1) {
        // const times=((new Date(res.data.vip_end_time).valueOf()-new Date().valueOf())/(1000*3600*24)).toFixed(0)
        const times=memberInfo.vip_end_time
        return `${(times.replace(/-/g,'.')).slice(0,times.indexOf(' '))}  `+this.$t('member.expire')
      }
    }
  },
  methods:{
    //会员剩余天数
    getMemberRemainNum(){
      getMemberRemainNum().then(res=>{
        if (res.data!=null) this.memberRemainInfo=res.data
        this.memberRemainNumInfo=res.data
        this.memberPrice = this.memberRemainNumInfo.svip_price
      })
    },
    async getUserInfo(){
      const {data: res}= await getUserInfo()
      this.userInfo=res
      if (res) {
        localStorage.setItem('userInfo', JSON.stringify(res))
      }
      if (res.charge_style2==2) this.handleCutMember(true)     //页面初次刷新8折续费SVIP价格显示为未打折价格的问题
      const {name,mobile,charge_style2,username} = res
      name===null||name===''? this.username=mobile:this.username=name.length>6 ?name.slice(0,6)+'...' :name
      if ((mobile===null||mobile==='')&&(name===null||name==='')) this.username=username
      charge_style2===2 ?this.isVip=false :this.isVip=true
    },
    handleCutMember(e){
      this.tabActive=e
      // if (!e) {
      //   this.memberPrice = 168
      //   this.originalPrice=1800
      //   if (this.userInfo.charge_style2==1) this.memberPrice=128
      // }else {
      //   this.memberPrice = 1980
      //   this.originalPrice=6980
      //   if (this.userInfo.charge_style2==2) this.memberPrice=1580
      // }
      if (!e) {
        this.memberPrice = this.memberRemainNumInfo.vip_price
        this.originalPrice=1800
      }else {
        this.memberPrice = this.memberRemainNumInfo.svip_price
        this.originalPrice=6980
      }
    },
    async sendAuthCode(kind){
      if (kind==='phone'){
        if (this.userInfo.asset_balance<this.memberPrice) return this.$message.warning(this.$t('member.balance_lack_tip'))
        this.isPhoneSent=false
        const timer=setInterval(()=>{
          this.phoneSeconds--
          if (this.phoneSeconds===0){
            this.phoneSeconds=60
            this.isPhoneSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,2,this.userInfo.mobile)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('member.sent_phone_auth_code'))
      }
      else if (kind==='email'){
        if (this.userInfo.asset_balance<this.memberPrice) return this.$message.warning(this.$t('member.balance_lack_tip'))
        this.isEmailSent=false
        const timer=setInterval(()=>{
          this.emailSeconds--
          if (this.emailSeconds===0){
            this.emailSeconds=60
            this.isEmailSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,1,this.userInfo.username)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('member.sent_email_auth_code'))
      }
    },
    //複製到粘貼板
    handleCopyAddress(){
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', this.topUpInfo.base58Address);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success(this.$t('member.copy_success'))
      }
      document.body.removeChild(input);
    },
    //  获取充值信息
    async getTopUpInfo() {
      const vipType = this.tabActive ? 2 : 1
      addMemberOrder(vipType).then(res => {
        // console.log(res);
      })
      this.topUpDialogVisible = true
      this.confirmOrderDialogVisible = false

      const res = await getUserAddress()
      if (!res) return
      if (res.success){
        this.topUpInfo.base58Address = res.data.base58Address
        // if (res.data.rechargeTarget != '1'){
        //   await this.changeRechargeAccount()
        // }
        await this.getUserAddressQrCode()
      } else {
        this.$message.error(res.message)
      }

      // axios.post(`https://houtai.felton.fit/fqwallet/api/tron-usdt/user-address?userId=${this.userInfo.id}`).then(res => {
      //   if (res.data.statusCode !== 200) return this.$message.error(this.$t('member.get_balance_error'))
      //   this.topUpInfo.base58Address = res.data.data.base58Address
      //   this.topUpInfo.qrCode = 'https://houtai.felton.fit/wallet/qrcode/' + res.data.data.qrCode
      // })
    },
    async getUserAddressQrCode(){
      const res = await getUserAddressQrCode()
      if (!res) return
      this.topUpDialogVisible = true
      this.topUpInfo.qrCode = window.URL.createObjectURL(res)
    },
    // async changeRechargeAccount(){
    //   const res = await setRechargeAccount(this.topUpInfo.rechargeTarget)
    //   if (!res) return
    //   if (res.success){
    //     this.topUpInfo.rechargeTarget = '1'
    //   } else {
    //     this.$message.error(res.message)
    //   }
    // },
  //  购买会员
    handleBuyMember(){
      if ((this.userInfo.charge_style2==2 || this.userInfo.charge_style2==3)&&!this.tabActive) return this.$message.warning(this.$t('member.demotion_buy_warn'))
      this.$refs.confirmOrderFormRef.validate( async valid=>{
        if (!valid) return
        let vipType=2;let sendType=1
        this.tabActive ? vipType=2 :vipType=1
        const {auth_code:vCode} =this.confirmOrderForm
        if (this.userInfo.username==''||this.userInfo.username==null) sendType=2
        const param={vipType ,vCode ,sendType}
        const res=await buyMember(param)
        if (!res.success) return this.$message.error(res.message)
        this.confirmOrderDialogVisible=false
        this.buySuccess=true
        this.getMemberRemainNum()
        this.getUserInfo('isDefaultActive')
      })
    },
  //  使用激活码兑换会员
    handleActiveMember(){
      this.$refs.activeCodeFormRef.validate(async valid=>{
        if (!valid) return
        const {code}= this.activeCodeForm
        const res= await activeCodeMember(code)
        if (!res.success) return this.$message.error(res.message)
        this.activeCodeInfo=res.data
        this.activeCodeDialogVisible=false
        this.buyPermanentSuccessDialog=true
        this.getMemberRemainNum()
        this.getUserInfo('isDefaultActive')
      })
    }
  }
}
</script>

<style scoped>
.black{
  background: linear-gradient(180deg, #101136 0%, #13141C 100%);
}
.container{
  max-width: 1200px;
  margin: 0 auto;
  padding: 21px 30px 0 30px;
  z-index: 100;
}
.nav{
  display: flex;
  justify-content: space-between;
}
.nav>div:first-child>img{
  vertical-align: middle;
  margin-right: 2px;
  cursor: pointer;
}
.nav>div:first-child>span{
  font-size: 14px;
  font-weight: 400;
  color: #F7EFE0;
  vertical-align: middle;
  cursor: pointer;
}
.nav>div:nth-child(2)>img{
  width: 37px;
  height: 21px;
  margin-right: 8px;
}
.nav>div:nth-child(2)>span{
  font-size: 16px;
  font-weight: 500;
  color: #F7EFE0;
}
.nav>div:nth-child(3)>span{
  font-size: 14px;
  font-weight: 400;
  color: #F7EFE0;
  cursor: pointer;
}

.content{
  padding: 105px 0 119px 0;
}
.content>.info{
  margin-top: 65px;
}
.content>.info>img{
  vertical-align: middle;
}
.content>.info>div{
  display: inline-block;
  vertical-align: middle;
  margin-left: 16px;
}
.content>.info>div>div:first-child>span{
  font-size: 18px;
  font-weight: 500;
  color: #F7EFE0;
  margin-right: 8px;
}
.content>.info>div>div:nth-child(2)>span:nth-child(1){
  font-size: 14px;
  font-weight: 400;
  color: #F7EFE0;
}
.content>.info>div>div:first-child{
  margin-bottom: 5px;
}
.content>.info>div>div:nth-child(2)>span:nth-child(2){
  font-size: 14px;
  font-weight: 400;
  color: #C6AB7E;
  padding: 0 16px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
}
.content>.info>div>div:nth-child(2)>span:nth-child(3),.content>.info>div>div:nth-child(2)>span:nth-child(4){
  font-size: 14px;
  font-weight: 400;
  color: #C6AB7E;
  margin-left: 16px;
  cursor: pointer;
}
.content>.info>div>div:nth-child(2)>span:nth-child(4){
  display: none;
}
/*title*/
.title{
  /*margin-top: 105px;*/
}
.title>div:nth-child(2){
  font-size: 50px;
  font-weight: 500;
  color: #F4E0BE;
}
.title>div:nth-child(3){
  font-size: 30px;
  font-weight: 400;
  color: #C6AB7E;
}

/*balance*/
.balance{
  margin-top: 62px;
}
.balance>span:first-child{
  font-size: 14px;
  font-weight: 400;
  color: #F7EFE0;
}
.balance>span:first-child>span{
  font-size: 20px;
  font-weight: 500;
  color: #C6AB7E;
  margin: 0 2px 0 8px;
}
.balance>span:nth-child(2)>span:first-child{
  font-size: 14px;
  font-weight: 400;
  color: #C6AB7E;
  padding: 0 16px 0 32px;
  border-right:1px solid rgba(198, 171, 126, 0.3) ;
  cursor: pointer;
}
.balance>span:nth-child(2)>span:nth-child(2){
  font-size: 14px;
  font-weight: 400;
  color: #C6AB7E;
  margin-left: 16px;
  cursor: pointer;
}
.nav-sm{
  display: none;
}
@media screen and (max-width: 1034px){
  .container>img{
    display: none;
  }
}
@media screen and (max-width: 900px){
  .nav-lg{
    display: none;
  }
  .nav-sm{
    display: flex;
  }
  .container{
    padding: 21px 16px 0 16px;
    z-index: 100;
    background: url("../../assets/img/member/circle_sm.svg")  top right no-repeat;
  }
  .content{
    padding: 40px 0 42px 0;
  }
  .content>.info{
    margin-top: 0;
  }
  .balance{
    margin-top: 22px;
  }
  .title{
    display: none;
  }
  .black>img{
    display: none;
  }
  .info>div>div:nth-child(2)>span:nth-child(3){
    display: none;
  }
  .content>.info>div>div:nth-child(2)>span:nth-child(4){
    display: inline-block;
  }
  .info>div>div:nth-child(2)>span:nth-child(2){
    padding: 0 16px 0 32px !important;
  }
}

/*  会员切换  */
.black-active{
  background: linear-gradient(180deg, #292A2C 0%, #09090A 100%);
}
/*弹框*/
>>> .el-dialog>div{
  background: #F4F1EC;
}
>>> .el-input .el-input__inner{
  background: #F4F1EC;
  border: 1px solid rgba(17, 18, 45, 0.2);
}
>>> .el-input input:focus{
  border: #96754A solid 1px;
}

/**********       验证码的布局     ******/
.el-form-item{
  position: relative;
}
.auth-code{
  position: absolute;
  top:0;
  right: 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #8E9298;
}
.auth-code>div>span:first-child{
  font-size: 14px;
  font-weight: 400;
  color: rgb(150, 117, 74);
  margin-right: 6px;
}

/*充值的对话框*/
/deep/ .topUpDialog .el-dialog__body {
  padding:15px 26px !important;
}
.topUp>div:first-child{
  font-size: 12px;
  color: #72777E;
}
.topUp>div:nth-child(2){
  margin: 8px 0 24px 0px;
}
.topUp>div:nth-child(2){
  font-size: 12px;
  color: #72777E;
}
.topUp>div:nth-child(4){
  font-size: 12px;
  color: #72777E;
}
.topUp>div:nth-child(4) img{
  width: 136px;
  height: 136px;
  display: inline-block;
  margin: 8px 0 16px 0;
}
.topUp>div:nth-child(5){
  padding: 11px 0 24px 0;
  border-top: 1px solid rgba(19, 20, 21, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topUp>div:nth-child(6)>span{
  font-size: 12px;
  color: #1E2329;
}
.topUp>div:nth-child(5)>img{
  display: inline-block;
}
.topUp>div:nth-child(7){
  font-size: 12px;
  color: #72777E;
}
.topUp>div:nth-child(8)>ul{
  margin: 8px 0 0 0 ;
  padding-left: 20px;
}
.topUp>div:nth-child(8)>ul>li{
  font-size: 14px;
  color: #1E2329;
  line-height: 25px;
}
.topUp>div:nth-child(8)>ul>li:nth-child(2){
  color:red;
}
@media screen  and (max-width: 900px){
  .topUp>div:nth-child(4) img{
    width: 100px;
    height: 100px;
    margin: 8px 0 8px 0;
  }
  .topUp>div:nth-child(5){
    padding: 10px 0 11px 0;
  }
  /deep/ .topUpDialog .el-dialog__body {
    padding:5px 20px !important;
  }
  /deep/ .topUpDialog .el-form-item {
    margin-bottom: 8px;
  }
}
@media screen and (min-width: 900px){
  .content{
    padding: 133px 0 119px 0;
  }
  .nav-lg>div:first-child{
    position: absolute;
    left: 36px;
    z-index: 1002;
    margin-top: 21px;
  }
  .nav-lg>div:nth-child(2){
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 1001;
    margin-top: 21px;
  }
  .nav-lg>div:nth-child(3){
    position: absolute;
    right: 36px;
    z-index: 1002;
    margin-top: 21px;
  }
}
</style>








