import { render, staticRenderFns } from "./Remind_pay.vue?vue&type=template&id=2dbbfaf6&scoped=true&"
import script from "./Remind_pay.vue?vue&type=script&lang=js&"
export * from "./Remind_pay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dbbfaf6",
  null
  
)

export default component.exports