<template>
  <el-dialog
      :visible.sync="remindDialog"
      width="510px"
      height="304px">
    <div style="text-align: center;">
      <img  style="width: 104px;height: 112px" src="../../../assets/img/member/remind_payment.svg" alt="">
      <div style="margin: 33px 0 24px 0 ;color: #000000;font-size: 24px">您的<span v-if="vipType">S</span>VIP訂單尚未支付</div>
    </div>
    <el-button @click="handleComplete" style="margin-bottom: 24px;width: 100%;color: #F7EFE0;;background: linear-gradient(180deg, #C6AB7E 0%, #947348 100%);">去完成</el-button>
    <div @click="remindDialog=false" style="cursor: pointer;color: #96754A;font-size: 14px;text-align: center;text-decoration: underline">下次再說</div>
  </el-dialog>
</template>

<script>
import {getUserMemberOrder} from "../../../server/member";
import {getUserInfo} from "../../../server/rebate";

export default {
  name: "Remind_pay",
  data(){
    return{
      remindDialog:false,
      vipType:false,
      userInfo:''
    }
  },
  created() {
    if(localStorage.getItem('showRemind')) return
    getUserInfo().then(res=>{
      console.log(res);
      this.userInfo=res.data
      getUserMemberOrder().then(res=>{
        console.log(res);
        if (res.data==null) return
        if (res.data.vip_type==2) this.vipType=true
        const times=((new Date().valueOf()-new Date(res.data.create_time).valueOf())/(1000*60))
        if (times>60||this.userInfo.deposit<(res.data.vip_type==1?168:1980)) return
        this.remindDialog=true
        localStorage.setItem('showRemind','show')
      })
    })

  },
  methods:{
    handleComplete(){
      this.remindDialog=false;
      this.$router.push('/grant_member?vipType='+(this.vipType?'2':'1'))
    }
  }
}
</script>

<style scoped>

</style>