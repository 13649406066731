import { render, staticRenderFns } from "./buy_success.vue?vue&type=template&id=6ee4a13a&scoped=true&"
import script from "./buy_success.vue?vue&type=script&lang=js&"
export * from "./buy_success.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ee4a13a",
  null
  
)

export default component.exports