<template>
  <div>
    <el-dialog
        :visible.sync="buySuccessDialog"
        width="510px"
        class="topUpDialog"
        height="304px">
      <div style="text-align: center;">
        <img  v-if="tabActive" style="width: 104px;height: 112px" src="../../../assets/img/member/svip_big.svg" alt="">
        <img v-else style="width: 104px;height: 112px" src="../../../assets/img/member/vip_big.svg" alt="">
        <div style="margin: 33px 0 24px 0 ;color: #1E2329;font-size: 18px">購買成功,您已升級為<span v-if="tabActive">S</span>VIP!</div>
      </div>
      <el-button @click="buySuccessDialog=false;$router.push('/robot')" style="margin-bottom: 24px;width: 100%;color: #F7EFE0;;background: linear-gradient(180deg, #C6AB7E 0%, #947348 100%);">去設置機器人</el-button>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "buy_success",
  data(){
    return{
      buySuccessDialog:false
    }
  },
  props:['tabActive','buySuccess'],
  watch:{
    buySuccess(newValue,value){
      this.buySuccessDialog=this.buySuccess
    },
    buySuccessDialog(newValue,value){
      if (!newValue) this.$emit('handleBuySuccess')
    }

  }
}
</script>

<style scoped>

</style>